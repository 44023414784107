<template>
  <v-app-bar
    id="app-bar"
    fixed
    app
    color="blue darken-3"
    height="57"
  >
    <v-btn
      elevation="0"
      dark
      icon
      small
      @click="modificaMenu"
    >
      <v-icon v-if="icone">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down"
      style="color: white"
      v-text="$route.meta.title"
    />

    <v-spacer />
    <base-notification v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'gestor'" />
    <base-menu-profile />
    <v-btn
      icon
      small
      color="white"
      title="Sair do portal"
      class="card-icon mx-2"
      @click="mensagem"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Utilities
  import { mapState, mapMutations } from 'vuex'
  import { logout } from '@/services/AuthService'
  import Swal from 'sweetalert2'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return { icone: false }
    },

    computed: {
      ...mapState(['drawer']),
    },

    created () {
      this.icone = this.value
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      modificaMenu () {
        this.setDrawer(!this.drawer)
        this.icone = !this.icone
      },
      mensagem () {
        Swal.fire({
          title: 'Tem certeza que deseja sair?',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#109010',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          focusConfirm: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.sair()
          }
        })
      },
      sair () {
        logout()
      },
    },
  }
</script>
